import React from 'react';

import Layout from '../components/Layout';


import gari1 from '../assets/images/gari-sq.png';
import mtmg2 from '../assets/images/meager2.jpg';
import swetha from '../assets/images/swetha_avatar.png';
import mtmg from '../assets/images/mtmeager1.jpg';
import cerro from '../assets/images/cerro_negro.jpg';
import gari from '../assets/images/gari2.jpg'

import config from '../../config';

const VolcIcon = () => {
  return (
    <svg className="volcano-icon" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116 116"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
        fill="currentColor" stroke="none">
        <path d="M577 903 c-14 -15 -21 -15 -37 -5 -43 26 -67 17 -151 -60 -23 -21
      -25 -61 -4 -78 8 -7 15 -18 15 -24 0 -7 11 -23 25 -36 29 -28 34 -70 8 -70
      -12 0 -39 -38 -83 -118 -36 -64 -102 -172 -147 -240 -46 -68 -83 -125 -83
      -127 0 -2 29 -13 65 -25 l65 -22 56 21 56 21 70 -21 70 -20 68 20 69 21 56
      -21 55 -21 65 22 c36 12 65 23 65 25 0 1 -38 59 -84 128 -46 70 -113 178 -148
      242 -43 78 -69 115 -80 115 -26 0 -23 40 6 73 13 15 30 27 38 27 8 0 23 6 33
      14 11 8 33 12 50 9 24 -3 38 3 73 35 48 46 53 72 17 107 -26 27 -72 33 -107
      14 -14 -8 -23 -8 -31 0 -16 16 -50 13 -70 -6z m67 -21 c-1 -22 -1 -22 18 -2
      52 55 156 19 133 -47 -10 -30 -77 -75 -104 -70 -14 3 -35 -2 -48 -10 -20 -13
      -29 -14 -51 -4 -21 10 -24 10 -15 0 9 -9 6 -19 -13 -44 -13 -17 -24 -42 -24
      -53 0 -19 -6 -22 -40 -22 -36 0 -40 3 -40 24 0 13 -11 34 -25 47 -13 13 -25
      29 -25 35 0 6 -7 17 -15 24 -34 28 -4 90 44 90 18 0 22 3 12 9 -10 7 -8 12 10
      25 29 20 48 20 76 1 18 -13 22 -12 39 6 25 28 69 22 68 -9z m-50 -309 c10 -21
      49 -91 87 -155 38 -64 69 -119 69 -122 0 -4 -7 -6 -15 -6 -17 0 -60 57 -110
      146 -39 70 -51 82 -68 65 -8 -8 -7 -31 1 -83 7 -49 8 -76 1 -83 -16 -16 -36
      20 -44 76 -8 59 -28 64 -41 12 -5 -23 -15 -38 -24 -38 -17 0 -18 22 -5 99 10
      65 -8 59 -54 -17 -18 -31 -39 -57 -47 -57 -22 0 -17 15 34 110 l47 90 76 0 75
      0 18 -37z m-164 -118 c-14 -67 -8 -88 22 -83 17 2 25 13 32 43 l10 40 15 -65
      c14 -58 19 -65 41 -65 28 0 29 5 15 113 -4 35 -4 62 1 62 5 0 37 -47 72 -104
      58 -97 87 -126 111 -112 8 5 106 -123 99 -129 -2 -1 -25 -9 -51 -18 -46 -15
      -51 -15 -102 4 l-54 20 -71 -21 -70 -21 -70 21 -71 21 -53 -20 c-51 -19 -56
      -19 -105 -3 -28 9 -50 18 -49 21 2 4 39 61 83 127 68 104 84 121 105 120 20
      -1 31 10 59 57 19 31 36 55 38 53 3 -2 -1 -30 -7 -61z"/>
        <path d="M475 830 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
      -4 -4 -10z"/>
      </g>
    </svg>
  )
}
const IndexPage = (props) => {
  return (
    <Layout location={props.location}>
      <section id="banner">
        <div className="inner">
          <div className="logo">
            <span className="icon">
              <VolcIcon />
            </span>
          </div>
          <h2>{config.heading}</h2>
          <p>{config.subHeading}</p>
        </div>
      </section>

      <section id="wrapper">
        <section id="one" className="wrapper spotlight style1">
          <div className="inner">
            <a href="/about" className="image">
              <img src={swetha} alt="" />
            </a>
            <div className="content">
              <h2 className="major">About Me</h2>
              <p>
                I'm Dr. Swetha Venugopal, a recent graduate from Simon Fraser University in Vancouver, Canada and Laboratoire Magmas et Volcans in Clermont-Ferrand, France.
              </p>
              <a href="/about" className="special">
                Learn more
              </a>
            </div>
          </div>
        </section>

        <section id="two" className="wrapper alt spotlight style2">
          <div className="inner">
            <a href="/about#thesis" className="image">
              <img src={gari1} alt="" />
            </a>
            <div className="content">
              <h2 className="major">PhD Thesis</h2>
              <p>
                Magmatic Sources to Volcanic Gas Emissions: Insight from the
                Garibaldi Volcanic Belt, Western Canada
              </p>
              <a href="/about#thesis" className="special">
                Learn more
              </a>
            </div>
          </div>
        </section>

        <section id="three" className="wrapper spotlight style3">
          <div className="inner">
            <a href="/about#nature" className="image" style={{
              height: 'auto',
              width: 'auto',
              maxWidth: '400px',
              maxHeight: '250px',
              borderRadius: 'none'
            }}>
              <img src={mtmg} alt="" />
            </a>
            <div className="content">
              <h2 className="major">Nature publication</h2>
              <p>
                Melt inclusion vapour bubbles: the hidden reservoir for major and volatile elements
              </p>
              <a href="/about#nature" className="special">
                Learn more
              </a>
            </div>
          </div>
        </section>

        <section id="four" className="wrapper alt style1">
          <div className="inner">
            <h2 className="major">Published Research</h2>
            <section className="features">
              <article className="">
                <a href="https://www.nature.com/articles/s41598-020-65226-3" target="_blank" rel="noopener noreferrer" className="image">
                  <img src={mtmg2} alt="" />
                </a>
                <h3 className="major">Melt inclusion vapour bubbles: the hidden reservoir for major and volatile elements</h3>
                <p>
                  Olivine-hosted melt inclusions (MIs) provide samples of magmatic liquids and their dissolved volatiles from deep within the plumbing system. Inevitable post-entrapment modifications can lead to significant compositional changes in the glass and/or any contained bubbles.
              </p>
                <a href="https://www.nature.com/articles/s41598-020-65226-3.pdf" className="special" target="_blank" rel="noopener noreferrer ">
                  Learn more
              </a>
              </article>
              <article>
                <a href="http://www.sfu.ca/volcanology/pdfs/Venugopal_JVGR'16.pdf" target="_blank" rel="noopener noreferrer" className="image">
                  <img src={cerro} alt="" />
                </a>
                <h3 className="major">Investigating the subsurface connection beneath Cerro Negro volcano and the El Hoyo Complex, Nicaragua</h3>
                <p>
                  Did you know that volcanoes can be connected at depth? In this paper, I used olivine-hosted melt inclusions to show that one of the most active volcanoes in Nicaragua, Cerro Negro, shares its plumbing system with the neighbouring, and dormant, volcano, Las Pilas-El Hoyo.
              </p>
                <a href="http://www.sfu.ca/volcanology/pdfs/Venugopal_JVGR'16.pdf" className="special" target="_blank" rel="noopener noreferrer ">
                  Learn more
              </a>
              </article>
              <article>
                <a target="_blank" rel="noopener noreferrer" href="https://www.researchgate.net/publication/336704121_Two_distinct_mantle_sources_beneath_the_Garibaldi_Volcanic_Belt_Insight_from_olivine-hosted_melt_inclusions" className="image">
                  <img src={gari} alt="" />
                </a>
                <h3 className="major">Two distinct mantle sources beneath the Garibaldi Volcanic Belt: Insight from olivine-hosted melt inclusions</h3>
                <p>
                  Not many people know that Canada has volcanoes too! Extending northwards from the High Cascades Arc, the Garibaldi Volcanic Belt exhibits a unique along-arc trend in its magmatic source.
              </p>
                <a target="_blank" rel="noopener noreferrer" href="https://www.researchgate.net/publication/336704121_Two_distinct_mantle_sources_beneath_the_Garibaldi_Volcanic_Belt_Insight_from_olivine-hosted_melt_inclusions" className="special">
                  Learn more
              </a>
              </article>
            </section>
            <ul className="actions">
              <li>
                <a href="/about#research" className="button">
                  Browse All
                </a>
              </li>
            </ul>
          </div>
        </section>
      </section>
    </Layout>
  );
}

export default IndexPage;
